import React, { useEffect, useState, useRef } from "react";
import styles from "./home.module.scss";
import { ArcMenu } from "@retisio/ui";
import { MenuData } from "./contstants";
import { DocService } from "./docs.service";
import { ArcLoader } from "@retisio/ui";
import { notification } from "../../shared/notification/notification";
import { useLocation, useSearchParams } from 'react-router-dom';

function Home() {
  const [docsData, setDocsData] = useState("");
  const [loader, setLoader] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [menuFinalData, setMenuFinalData] = useState([]);
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([]);
  const [defaultOpenKeys, setDefaultOpenKeys] = useState([]);
  const refContainer = useRef("");
  const useQuery= () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  const [searchParams, setSearchParams] = useSearchParams();



  useEffect(() => {
    let docPath = query.get('docPath');

    const noRefCheck = (data) => {
      console.log(data);
      if (searchParams.has('docPath')) {
        searchParams.delete('docPath');
        setSearchParams(searchParams);
      }
      data.URL && getData(data.URL);
      data.staticDocFile && getStaticFile(data.staticDocFile);
    };

    const getMenuData = (ModMenuData) => {
      let finalData = ModMenuData.map((item) => {
        if (item.hassubmenu && item.submenuItems) {
          item.submenuItems = getMenuData(item.submenuItems);
        }
        item.onClick = noRefCheck;
        return item;
      });
      return finalData;
    };

    setMenuFinalData(getMenuData(MenuData));
    // getData(MenuData[0].submenuItems[0]['URL']);
    if(docPath){
      var objfin,menuData=[],selectedKey=[];
      // eslint-disable-next-line array-callback-return
      docPath.split('/').map((name)=>{
        if(!objfin){
          let filData = MenuData.find((obj)=>name===obj.name);
          filData.submenuItems && menuData.push(filData.key);
          !filData.submenuItems && selectedKey.push(filData.key);
          objfin = filData.submenuItems && filData.submenuItems.length>0 ? filData.submenuItems : filData;
        }else{
          objfin = objfin.find((obj)=>name===obj.name);
          selectedKey.push(objfin.key);
        }
      });
      setDefaultOpenKeys(menuData);
      setDefaultSelectedKeys(selectedKey);
      if(objfin && objfin.URL){
        objfin.URL && getData(objfin.URL);
      }else if(objfin && objfin.staticDocFile){
        objfin.staticDocFile && getStaticFile(objfin.staticDocFile);
      }else{
        getStaticFile(MenuData[0].staticDocFile);
      }
    }else{
      getStaticFile(MenuData[0].staticDocFile);
    }
    
  }, []);

  const getStaticFile = (fileName) => {
    setHasError(false);
    setLoader(true);
    import("../../static/" + fileName).then(({ default: data }) => {
      setDocsData(data.docHtml);
      setLoader(false);
      setTimeout(() => {
        refContainer.current.scrollIntoView({ behavior: 'smooth' });
      },100);
    });
  };

  const getData = (URL) => {
    setHasError(false);
    setLoader(true);
    DocService.getData(URL)
      .then((data) => {
        data.docHtml && setDocsData(data.docHtml);
        setLoader(false);
        setTimeout(() => {
          refContainer.current.scrollIntoView({ behavior: 'smooth' });
        },100);
      })
      .catch((err) => {
        setLoader(false);
        notification(err, "error");
        setHasError(true);
        setDocsData("");
      });
  };

  const createMarkup = (html) => {
    return { __html: html };
  };

  return (
    <>
      {loader && <ArcLoader />}
      {hasError && <div></div>}
      {menuFinalData.length > 0 && (
        <div className={styles.wrapper}>
          <div className="sidebar">
            <ArcMenu
              defaultSelectedKeys={defaultSelectedKeys}
              defaultOpenKeys={defaultOpenKeys}
              displayKey="name"
              collapsed={false}
              menuItems={menuFinalData}
              mode="inline"
              theme="light"
            />
          </div>
          <div className="contentContainer">
            {!hasError && (
              <div
                ref={refContainer}
                className="container"
                dangerouslySetInnerHTML={createMarkup(docsData)}
              ></div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
