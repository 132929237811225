import React, { useContext } from "react";
import { AuthContext } from "@retisio/rbac";
import { Arc404, ArcButton } from "@retisio/ui";
import Layout from './layout';
import Home  from "./pages/home";
import Login from "./pages/login";
import Api  from "./pages/apis";
import notimg from "./styles/asserts/nodata.svg";
import { useRoutes, Navigate } from "react-router-dom";
import ForgotPwd from "./pages/forgotpwd";
import ResetPwd from "./pages/resetpwd";

function Router() {
  const authCxt = useContext(AuthContext);
  
  return useRoutes([
    { path: "/login", element: authCxt?.authenticated ? <Navigate to="/" /> : <Login /> },
    { path: "/forgotpassword", element: <ForgotPwd /> },
    { path: "/resetpassword/:id", element: <ResetPwd /> },
    {
      element: authCxt?.authenticated ? <Layout /> : <Navigate to="/login"/>,
      children: [
        { path: "", element:  <Navigate to="/home"/> },
        { path: "home", element: <Home /> },
        { path: "API", element: <Api /> },
        { path: "*", element: <Arc404
          title={"404"}
          icon={notimg}
          subTitle={"Sorry, the page you visited does not exist."}
          extra={<ArcButton type="primary" label="Back to Home" />}
        /> }
      ]
    },
    {
      path: "*", element: <Arc404
        title={"404"}
        icon={notimg}
        subTitle={"Sorry, the page you visited does not exist."}
        extra={<ArcButton type="primary" label="Back to Home" />}
      />
    }
  ]);
};
export default Router;
