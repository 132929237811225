import React, {   useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ArcIcon } from '@retisio/ui';
import { AuthService } from './resetpassword.service';
import rightIcon from "./check-circle.png";

import styles from './auth.module.scss';
import ResetPassword from '../../shared/account/resetpassword';
import { checkPasswordStrength
} from '../../shared/utilities/checkPasswordStrength';
import { ResetPasswordFormFields } from './constant';
import { notification } from '../../shared/notification/notification';

function ResetPwd() {
  const {id} = useParams();
  const [isLoader, setLoader] = useState(false);
//   const authCxt = useContext(AuthContext);
  const [showConfirmation, setShowConfirmation] = useState(false);

  let navigate = useNavigate();
  let pwdStengthFieldId = 'password_strength';

  const handleOnKeyUp = (value)=>{
    checkPasswordStrength(value, pwdStengthFieldId);
  };

  const RESETPASSWORDFORM = ResetPasswordFormFields(handleOnKeyUp);
  
  const handleFinish = (values) => {
    setLoader(true);
   
    let formBody = {
      password: values.password,
      link: id
    };
    AuthService.signIn(formBody)
      .then((data) => {
        setLoader(false);
        setShowConfirmation(true);
      }, (err) => {
        notification(err,"error");
        navigate(`/${process.env.REACT_APP_DOC_ID}/resetpassword/${id}`);
        setLoader(false);
      });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.bgImageCls}></div>
      <div className={styles.login}>
        <ResetPassword fields={RESETPASSWORDFORM} handleSubmit={handleFinish} isLoader={isLoader}
          submitBtnLabel={'reset'} imgIcon={rightIcon} showConfirmation={showConfirmation}
          pwdStengthFieldId={pwdStengthFieldId}/>
        <div className={styles.banner}>
          <div className={styles.slider}>
            <ArcIcon
              name={`bg`}
              size={{ height: 350, width: 300 }}
            />
            <div className={styles.footer}>

              <h6>Manage your product, inventory, pricing & promotion</h6>
              <span>A unified experience hub for modern retailers to build and
                manage unique digital experiences and provide seamless
                shopping experience for consumers</span>
            </div>
          </div>
          <div className={styles.dots}>
            <div className={styles.active} />
            <div className={styles.circle} />
            <div className={styles.circle} />
            <div className={styles.circle} />
          </div>
        </div>
      </div>
      <div className={styles.bgImageRight}></div>
      <div className={styles.bgImageRight2}></div>
    </div>
  );
}

export default ResetPwd;

