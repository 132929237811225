import React, { useEffect, useState } from "react";
import { ArcMenu } from "@retisio/ui";
import styles from './apis.module.scss';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { ArcLoader } from '@retisio/ui';
import { notification } from "../../shared/notification/notification";




function Api() {
  // const [name, setName] = useState('Account');
  const [fileNames, setFileNames] = useState([]);
  const [postManData, setpostManData] = useState('');
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    const tabClick = (item)=>{
      setLoader(true);
      selectDoc(item.name);
    };
    import("../../final_postman_collection/collection.json").then(
      ({ default: myData }) => {
        let finalData = myData.collectionNames.map((item,i)=>{
          return {
            name:item,
            key:i,
            onClick:tabClick
          };
        });
        setLoader(false);
        setFileNames(finalData);
      }
    );
    const selectDoc = (name)=>{
      import("../../final_postman_collection/"+name+".json").then(
        ({ default: myData }) => {
          setpostManData(myData);
          setLoader(false);
        }
      ).catch((e)=>{
        setpostManData('');
        notification({errors:[{message:'Something went wrong'}]}, 'error');
        setLoader(false);
      });
    };
    selectDoc('Account');

  }, []);

  return(
    <>
      {loader && <ArcLoader />}
      <div className={styles.wrapper}>
        <div className={styles.sidebar}>
          {fileNames.length>0 && <ArcMenu
            defaultSelectedKeys={["0"]}
            displayKey="name"
            collapsed={false}
            menuItems={fileNames}
            mode="inline"
            theme="light"
          />}
        </div>
        <div className={styles.container}>
          {postManData && <SwaggerUI spec={postManData} />}
        </div>
      </div>
    </>
  );

}

export default Api;