import API from "@retisio/api";
export const Interceptors = {
  requestInterceptors(Method, URI, reqData) {
    // reqData.header = {"test":"test",...reqData.header};
    switch (Method) {
    case "POST":
      return API.post(URI, reqData);
    case "PUT":
      return API.update(URI, reqData);
    case "GET_BULK":
      return API.getBulk(URI, reqData);
    default:
      return API.get(URI);
    }
  }
};
