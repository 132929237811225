import React from 'react';
import { ArcIcon, ArcButton, ArcForm, Form } from '@retisio/ui';
import styles from './auth.module.scss';


function ForgotPassword({fields, handleSubmit,isLoader, submitBtnLabel,emailIcon,showConfirmation}) {

  const [form] = Form.useForm();

  return (
    <div className={`${styles.formWrapper}`}>
      <div className={styles.header}>
        <ArcIcon
          name={`logo`}
          size={{ height: 70, width: 108 }}
        />
      </div>

      <div className={styles.loginConfirm}>
        <h1>FORGOT PASSWORD</h1>
        {!showConfirmation ?
          <>
            <div className={styles.formData}>
              <ArcForm fields={fields} form={form} onFinish={handleSubmit}
                formName={"ForgotPassword"} />
            </div>
            <div className={styles.signupBtn}>
              <ArcButton loading={isLoader} onClick={() => form.submit()} type={'primary'} label={submitBtnLabel} />
            </div>
          </>:
          <>
            <img src={emailIcon} alt="Mail" />
            <p>
                An e-mail has been sent to registered mail with password reset
                instructions.
            </p>
          </>}
      </div>
    </div>
  );
}

export default ForgotPassword;

