import React, { lazy, useContext, useEffect, useState } from "react";
import styles from "./apphome.module.scss";
import { Outlet } from "react-router";
import { AuthContext } from "@retisio/rbac";
import { CommonService } from "./common.service";
import { ArcLoader } from '@retisio/ui';

const SideNavigation = lazy(() => import("./sidenavigation"));

function Layout() {
  const [loader, setLoader] = useState(false);
  // const {currentTab} = useContext(BxcContext);
  const {
    user,
    setUserRoles
  } = useContext(AuthContext);

  useEffect(() => {
    setLoader(true);
    user.businessUserId &&
      CommonService.getUserDetails(user.businessUserId).then((payload) => {
        if (payload.roles) {
          setLoader(true);
          CommonService.getUsrRoles(payload.roles).then((rolesData) => {
            let permissionIds = [];
            rolesData.forEach((roleData) => {
              permissionIds = [...permissionIds, ...roleData.role.permissionIds];
            });
            setUserRoles({ ...payload, permissionIds });
            setLoader(false);
          });
        }
        payload && setUserRoles(payload);
        setLoader(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div className={styles.wrapper}>
    {loader && <ArcLoader />}
    <SideNavigation />
    <div className={styles.mainWrapper}>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  </div>;
}

export default Layout;