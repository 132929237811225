const MenuData = [
  {
    key: "1",
    name: "Home",
    staticDocFile: "Home.json"
  },
  {
    key: "2",
    name: "API Specification",
    staticDocFile: "API-spec.json"
  },
  {
    key: "3",
    name: "Architecture",
    staticDocFile: "ARC-Architecture.json"
  },
  {
    key: "4",
    name: "DataSources",
    staticDocFile: "Datastore.json"
  },
  {
    key: "5",
    isActive: false,
    isLocked: false,
    name: "Microservices",
    hassubmenu: true,
    submenuItems: [
      {
        key: "5-3",
        isActive: false,
        isLocked: false,
        name: "Account",
        URL: "account/api/v1/wiki/doc/account"
      },
      {
        key: "5-4",
        isActive: false,
        isLocked: false,
        name: "RBAC",
        URL: "rbac/api/v1/wiki/doc/RBAC"
      },
      {
        key: "5-5",
        isActive: true,
        isLocked: false,
        name: "Authorization",
        URL: "rbac/api/v1/wiki/doc/authorization"
      },
      {
        key: "5-14",
        name: "Product-Catalog",
        URL: "catalog/api/v1/wiki/doc/productcatalog"
      },
      {
        key: "5-11",
        isActive: false,
        isLocked: false,
        name: "Shopping-Cart",
        URL: "shopping-cart/api/v1/wiki/doc/shoppingcart"
      },
      {
        key: "5-7",
        isActive: false,
        isLocked: false,
        name: "Pricing",
        URL: "price/api/v1/wiki/doc/price"
      },
      {
        key: "5-6",
        isActive: false,
        isLocked: false,
        name: "Promotion",
        URL: "promotion/api/v1/wiki/doc/promotions"
      },
      {
        key: "5-12",
        isActive: false,
        isLocked: false,
        name: "Channel-Master",
        URL: "channel/api/v1/wiki/doc/channelmaster"
      },
      {
        key: "5-8",
        isActive: false,
        isLocked: false,
        name: "Inventory",
        URL: "inventory/api/v1/wiki/doc/inventory"
      },
      {
        key: "5-2",
        name: "Catalog-Search",
        URL: "catalog-search/api/v1/wiki/doc/catalogsearch"
      },
      {
        key: "5-1",
        isActive: false,
        isLocked: false,
        name: "Search-Config",
        URL: "search-config/api/v1/wiki/doc/searchconfig"
      },
      {
        key: "5-9",
        isActive: false,
        isLocked: false,
        name: "Content",
        URL: "content/api/v1/wiki/doc/content"
      },
      {
        key: "5-10",
        isActive: false,
        isLocked: false,
        name: "Digital-Media",
        URL: "digital-media/api/v1/wiki/doc/dom"
      }
    ]
  },
  {
    key: "6",
    isActive: false,
    isLocked: false,
    name: "User Guides",
    hassubmenu: true,
    submenuItems: [
      {
        key: "6-1",
        name: "Intelligent Recommendation Business User",
        staticDocFile: "Intelligent Recommendation Business User Guide.json"
      }
      // {
      //   key: "6-1",
      //   name: "DIGITAL ASSET MANAGEMENT",
      //   staticDocFile: "RETISIO DIGITAL ASSET MANAGEMENT USER GUIDE.json"
      // },
      // {
      //   key: "6-2",
      //   name: "BXC",
      //   staticDocFile: "RETISIO_BxC_User_Guide.json"
      // },
      // {
      //   key: "6-3",
      //   name: "Content",
      //   staticDocFile: "RETISIO_Content_Guide.json"
      // },
      // {
      //   key: "6-4",
      //   name: "Promotion",
      //   staticDocFile: "RETISIO_Promotions_Guide.json"
      // },
      // {
      //   key: "6-5",
      //   name: "Search",
      //   staticDocFile: "RETISIO_Search_Guide.json"
      // }
    ]
  },
  // {
  //   key: "6",
  //   isActive: false,
  //   isLocked: false,
  //   name: "Intelligent Recommendation Business User Guide",
  //   staticDocFile: "Intelligent Recommendation Business User Guide.json"
  // },
  {
    key: "7",
    isActive: false,
    isLocked: false,
    name: "Development Guide",
    hassubmenu: true,
    submenuItems: [
      {
        key: "7-1",
        name: "Micro-Front-Ends-Development",
        staticDocFile: "MF-Development-Guide.json"
      },
      {
        key: "7-2",
        name: "Microservice Development",
        staticDocFile: "Microservices-Development-Guide.json"
      }
    ]
  },
  {
    key: "8",
    isActive: false,
    isLocked: false,
    name: "Deployment Guide",
    hassubmenu: true,
    submenuItems: [
      {
        key: "8-1",
        name: "Microservice Deployment",
        staticDocFile: "MS-Deployment-Guide.json"
      },
      {
        key: "8-2",
        name: "Micro-Front-Ends Deployment",
        staticDocFile: "MF-Deployment-Guide.json"
      },
      {
        key: "8-3",
        name: "Kafka",
        staticDocFile: "kafka.json"
      },
      {
        key: "8-4",
        name: "Elastic Search",
        staticDocFile: "ElasticSearch.json"
      },
      {
        key: "8-5",
        name: "Postgres",
        staticDocFile: "Postgres.json"
      },
      {
        key: "8-6",
        name: "Monitoring",
        staticDocFile: "Kamon.json"
      }
    ]
  },
  {
    key: "9",
    isActive: false,
    isLocked: false,
    name: "Common Library",
    hassubmenu: true,
    submenuItems: [
      {
        key: "9-1",
        name: "Schema Extension",
        // staticDocFile: "Schema-Extension.json",
        URL: "content/api/v1/wiki/doc/extension"


      },
      {
        key: "9-2",
        name: "Workflow & Workspace",
        // staticDocFile: "Workflow.json"
        URL: "content/api/v1/wiki/doc/workflow"
      },
      {
        key: "9-3",
        name: "Quartz jobs scheduler",
        staticDocFile: "Quartz-scheduler.json"
      }
    ]
  },
  {
    key: "10",
    isActive: false,
    isLocked: false,
    name: "DevOps How-To's",
    hassubmenu: true,
    submenuItems: [
      {
        key: "10-1",
        name: "GKE Node Upgrades",
        staticDocFile: "Schema-Extension.json"
      },
      {
        key: "10-2",
        name: "Backup & Recovery",
        staticDocFile: "Backup-Recovery.json"
      }
    ]
  }
];

export { MenuData };
