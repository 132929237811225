import React, {   useState } from 'react';
import { useNavigate } from 'react-router';
import { ArcIcon } from '@retisio/ui';
import { AuthService } from './forgotpassword.service';
import emailIcon from "./contact-mail.png";

import styles from './auth.module.scss';

import { FORGOTPASSWORDFORM } from './constant';
import ForgotPassword from '../../shared/account/forgotpassword';
import { notification } from '../../shared/notification/notification';

function ForgotPwd() {
  const [isLoader, setLoader] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  let navigate = useNavigate();

  const handleFinish = (values) => {
    setLoader(true);
    AuthService.signIn(values)
      .then((data) => {
        setLoader(false);
        setShowConfirmation(true);
      }, (err) => {
        notification(err,"error");
        navigate('/forgotpassword');
        setLoader(false);
      });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.bgImageCls}></div>
      <div className={styles.login}>
        <ForgotPassword fields={FORGOTPASSWORDFORM} handleSubmit={handleFinish} isLoader={isLoader}
          submitBtnLabel={'submit'} emailIcon={emailIcon} showConfirmation={showConfirmation}/>
        <div className={styles.banner}>
          <div className={styles.slider}>
            <ArcIcon
              name={`bg`}
              size={{ height: 350, width: 300 }}
            />
            <div className={styles.footer}>

              <h6>Manage your product, inventory, pricing & promotion</h6>
              <span>A unified experience hub for modern retailers to build and
                manage unique digital experiences and provide seamless
                shopping experience for consumers</span>
            </div>
          </div>
          <div className={styles.dots}>
            <div className={styles.active} />
            <div className={styles.circle} />
            <div className={styles.circle} />
            <div className={styles.circle} />
          </div>
        </div>
      </div>
      <div className={styles.bgImageRight}></div>
      <div className={styles.bgImageRight2}></div>
    </div>
  );
}

export default ForgotPwd;

