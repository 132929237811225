const FORGOTPASSWORDFORM = [
  {
    type: "email",
    name: "userName",
    label: "Email Address",
    placeholder: 'business@example.com',
    rules: [
      {
        type: 'email',
        message: 'The input is not valid E-mail!'
      },
      {
        required: true,
        message: 'Please input your E-mail!'
      }
    ]
  }];

export {FORGOTPASSWORDFORM};