import React from 'react';
import { useNavigate } from 'react-router';
import { ArcIcon, ArcButton, ArcForm, Form } from '@retisio/ui';

import styles from './auth.module.scss';

function ResetPassword({fields, handleSubmit,isLoader, submitBtnLabel,imgIcon,showConfirmation,pwdStengthFieldId}) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  
  return (
    <div className={`${styles.formWrapper}`}>
      <div className={styles.header}>
        <ArcIcon
          name={`logo`}
          size={{ height: 70, width: 108 }}
        />
      </div>
      <div className={styles.loginConfirm}>
        <h1>RESET PASSWORD</h1>
        {!showConfirmation ?
          <>
            <div className={styles.formData}>
              <ArcForm fields={fields} form={form} onFinish={handleSubmit}
                formName={"RESETPASSWORDFORM"} />
              <span id={pwdStengthFieldId} className="password-policy"></span>
            </div>
            <div className={styles.signupBtn}>
              <ArcButton loading={isLoader} onClick={() => form.submit()} type={'primary'} label={submitBtnLabel} />
            </div>
          </>:
          <>
            <img src={imgIcon} alt="confirm" />
            <p>Your password has been updated</p>
            <div  className={styles.signupBtn}>
              <ArcButton
                loading={isLoader}
                type={'primary'}
                label={'CONTINUE TO LOGIN'}
                onClick={() => navigate("/login")}
              />
            </div>
          </>}
      </div>
    </div>
  );
}

export default ResetPassword;

