const LOGINFORM = [
  {
    type: "email",
    name: "userName",
    label: "Email Address",
    placeholder: 'business@example.com',
    rules: [
      {
        type: 'email',
        message: 'The input is not valid E-mail!'
      },
      {
        required: true,
        message: 'Please input your E-mail!'
      }
    ]
  },
  {
    type: "password",
    name: "password",
    label: "Password",
    placeholder: '********',
    rules: [
      {
        required: true,
        message: 'Please input your password!'
      }
    ]
  }];

export {LOGINFORM};