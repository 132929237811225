import { Interceptors } from "../shared/interceptors";
export const CommonService = {
  getUserDetails(userId) {
    let URI = `${process.env.REACT_APP_RBAC_BASE}/api/v1/internal-users/${userId}`;
    return Interceptors.requestInterceptors('GET',URI);
  },
  getUsrRoles(roles){
    let URLS = [];
    roles.map((role)=>URLS.push(`${process.env.REACT_APP_RBAC_BASE}/api/v1/roles/${role}`));
    return Interceptors.requestInterceptors('GET_BULK',URLS);
  },
  getCookie(name){
    var cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");
      if (name === cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }
    
};
