import { ArcNotification } from "@retisio/ui";

export function notification(response,type){
  let message;
  if(type==='success'){
    if(response?.message){
      message=response?.message;
    }else{
      message=response?.statusMessage;
    }
  }else if(type==='error'){
    if(response && response.errors && response?.errors[0]?.message){
      message=response?.errors[0]?.message;
    }
    else{
      message=response?.errorMessage;
    }
  }else if(type==='warning'){
    message=response;
  }
  else if(type==='info'){
    message=response;
  }
  let props = {
    message: message,
    duration: 2,
    type: type,
    placement: "topRight"
  };
  ArcNotification({ ...props });
}