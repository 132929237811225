export const ResetPasswordFormFields = (handleOnKeyUp) => {
  const RESETPASSWORDFORM = [
    {
      type: "password",
      name: "password",
      label: "Reset Password",
      pattern:"^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,}$",
    // eslint-disable-next-line max-len
      title:"Password must be eight characters including one uppercase letter, one lowercase, one special character and alphanumeric characters",
      minlength:"8",
      placeholder: 'password',
      onKeyUp: (e)=>{handleOnKeyUp(e.target.value);},
      rules: [
        {
          type: 'password',
          message: 'The input is not valid Password!'
        },
        {
          required: true,
          message: 'Please input your Password!'
        }
      ]
    }];

  return RESETPASSWORDFORM;
};
