const getMenu = (menus, user, isTopMenu = false) => {
  const WorkspacePermissions = user.permissionIds;
  function checkPermission(menu) {
    if (typeof user.roles !== "undefined") {
      if (menu.permissions.length === 0) {
        return true;
      }
      return(WorkspacePermissions.some(r=> menu.permissions.includes(r)));
    }
  }

  if (isTopMenu) {
    if (menus && menus.filter(checkPermission).length > 0) {
      const topmenus = menus.filter(checkPermission);
      const isSameAPP = topmenus.filter((tmenu) => tmenu.appId === process.env.REACT_APP_DOC_ID);
      if (isSameAPP.length < 1) {
        console.log("app is no there", topmenus[0]);
        // document.location = topmenus[0].href;
      }
    }
  }
  return menus && menus.filter(checkPermission);
};

export default getMenu;
