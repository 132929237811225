import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from "@retisio/rbac";
import { useNavigate } from 'react-router';
import { ArcIcon, ArcButton, ArcForm, Form } from '@retisio/ui';
import { AuthService } from './login.service';



import styles from './auth.module.scss';

import { LOGINFORM } from './constant';
import { notification } from '../../shared/notification/notification';

function Login() {
  const [isLoader, setLoader] = useState(false);
  const authCxt = useContext(AuthContext);

  useEffect(() => {
    if (authCxt && authCxt.authToken && authCxt.authenticated) {
      navigate("/");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const [form] = Form.useForm();
  let navigate = useNavigate();

  const handleFinish = (values) => {
    setLoader(true);
    AuthService.signIn(values)
      .then((data) => {
        authCxt.handleAuthentication(data);
        if(data){
          navigate('/');
        }
     
        setLoader(false);
      }, (err) => {
        notification(err,"error");
        navigate('/login');
        setLoader(false);
      });
  };

  const handleForgotPassword = ()=>{
    navigate('/forgotpassword');
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.bgImageCls}></div>
      <div className={styles.login}>
        <div className={`${styles.formWrapper}`}>
          <div className={styles.header}>
            <ArcIcon
              name={`logo`}
              size={{ height: 70, width: 108 }}
            />
          </div>
          <h6>Welcome Back</h6>
          <span className={`${styles.small} small`}>Manage your product and inventory as well</span>
          <div className={styles.formData}>
            <ArcForm fields={LOGINFORM} form={form} onFinish={handleFinish} formName={"login"} />
          </div>
          <div className={styles.signUpOptions}>
            <div className={styles.line} />
            <div className={styles.otherOptions}>Sign in with</div>
            <div onClick={handleForgotPassword} className={styles.forgot}>Forgot Password?</div>
          </div>

          <div className={styles.signupBtn}>
            <ArcButton loading={isLoader} onClick={() => form.submit()} type={'primary'} label={'sign in'} />
          </div>
        </div>
        <div className={styles.banner}>
          <div className={styles.slider}>
            <ArcIcon
              name={`bg`}
              size={{ height: 350, width: 300 }}
            />
            <div className={styles.footer}>

              <h6>Manage your product, inventory, pricing & promotion</h6>
              <span>A unified experience hub for modern retailers to build and
                manage unique digital experiences and provide seamless
                shopping experience for consumers</span>
            </div>
          </div>
          <div className={styles.dots}>
            <div className={styles.active} />
            <div className={styles.circle} />
            <div className={styles.circle} />
            <div className={styles.circle} />
          </div>
        </div>
      </div>
      <div className={styles.bgImageRight}></div>
      <div className={styles.bgImageRight2}></div>
    </div>
  );
}

export default Login;

