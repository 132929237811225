import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import './styles/index.scss';
import '@retisio/ui/dist/lib/base.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
serviceWorker.unregister();